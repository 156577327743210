// import {
//   createBrowserRouter,
//   createRoutesFromElements,
//   Route,
//   RouterProvider,
// } from "react-router-dom";
// import Header from "./components/header";
// import WalletProvider from "./context/WalletProvider";
// import { NotificationProvider } from "./context/NotificationContext";
// import StatusPage from "./routes/StatusPage";
// import NotFoundPage from "./pages/404";
// import DashboardPage from "./routes/dashboard/DashboardPage";
// import Collection from "./components/Collection";
// import InscriptionPage from "./routes/InscriptionPage";
// import Experimental from "./routes/Experimental";
// import { RequireAuth } from "./components/RequireAuth";
// import { Login } from "./components/Login";
import React from 'react';
import landingImage from './landing.png';

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route path="/">
//       <Route index element={<Collection cardName="series01card11" />} />
//       <Route path="/collection/:name" element={<Collection />} />
//       <Route path="/status/:someId" element={<StatusPage />} />
//       <Route path="/dashboard" element={<DashboardPage />} />
//       <Route path="/inscription/:inscriptionId" element={<InscriptionPage />} />
//       <Route path="/*" element={<NotFoundPage />} />
//       <Route path="/experimental" element={<Experimental />} />
//     </Route>,
//   ),
// );

function App() {
  return (
    // <NotificationProvider>
    //   <WalletProvider>
    //     <div className="flex min-h-screen flex-col bg-black">
    //       <Header />
    //       <main className="flex-grow bg-black">
    //         <RouterProvider router={router} />
    //       </main>
    //       <footer className="bottom-0 mt-16 w-full bg-black p-4 text-center font-typewriter text-white">
    //         &copy; {new Date().getFullYear()} COUNTERFEIT CULTURE
    //       </footer>
    //     </div>
    //   </WalletProvider>
    // </NotificationProvider>
    <div style={styles.container}>
    <img src={landingImage} alt="Landing" style={styles.image} />
  </div>
);
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'black',
  },
  image: {
    maxWidth: '30vw',
    maxHeight: '30vh',
  },
};
  

export default App;
